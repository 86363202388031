define("ember-svg-jar/inlined/add-assignee-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.1155 11.4999C13.72 11.4999 15.8295 9.37431 15.8295 6.74994C15.8295 4.12556 13.72 1.99994 11.1155 1.99994C8.51104 1.99994 6.40152 4.12556 6.40152 6.74994C6.40152 9.37431 8.51104 11.4999 11.1155 11.4999ZM11.1155 4.37494C12.4119 4.37494 13.4725 5.44369 13.4725 6.74994C13.4725 8.05619 12.4119 9.12494 11.1155 9.12494C9.81918 9.12494 8.75852 8.05619 8.75852 6.74994C8.75852 5.44369 9.81918 4.37494 11.1155 4.37494ZM11.1155 12.6874C12.3913 12.6874 13.5916 12.8396 14.7025 13.0938C14.0216 13.6665 13.4699 14.3879 13.0972 15.208C12.4729 15.1161 11.8106 15.0624 11.1155 15.0624C8.32248 15.0624 6.05975 15.9293 4.65733 16.6537C4.28021 16.8437 4.04451 17.2474 4.04451 17.6987V18.6249H12.6354C12.7715 19.4923 13.0934 20.2979 13.559 20.9999H1.6875V17.6987C1.6875 16.3687 2.40639 15.1456 3.58489 14.5399C5.56478 13.5187 8.13392 12.6874 11.1155 12.6874ZM17.6662 22.4179V18.7274H13.9754V17.0237H17.6662V13.3327H19.3698V17.0237H23.0605V18.7274L19.3698 18.7274V22.4179H17.6662Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});