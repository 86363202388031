define("ember-svg-jar/inlined/no-assignee-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<rect width=\"24\" height=\"24\" rx=\"12\" fill=\"#EBEFF2\"/>\n<path d=\"M13.59 11.47C13.69 11.25 13.75 11.01 13.75 10.75C13.75 9.785 12.965 9 12 9C11.74 9 11.5 9.06 11.28 9.16L13.59 11.47Z\" fill=\"#4B5565\"/>\n<path d=\"M12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7ZM12 13.5C10.84 13.5 9.775 13.9 8.93 14.56C8.35 13.865 8 12.975 8 12C8 11.075 8.315 10.225 8.845 9.55L10.275 10.98C10.38 11.76 10.99 12.375 11.77 12.475L12.87 13.575C12.585 13.525 12.295 13.5 12 13.5ZM15.155 14.45L9.55 8.845C10.225 8.315 11.075 8 12 8C14.21 8 16 9.79 16 12C16 12.925 15.685 13.77 15.155 14.45Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});