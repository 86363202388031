define("ember-svg-jar/inlined/priority-filled-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M13.5 3.75H4.5V9.75H13.5L11.4376 6.75L13.5 3.75Z\" fill=\"#D92D20\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.25 1.5V3H13.5C13.7785 3 14.0341 3.15434 14.1638 3.40083C14.2934 3.64732 14.2758 3.94537 14.118 4.17488L12.3477 6.75L14.118 9.32512C14.2758 9.55463 14.2934 9.85268 14.1638 10.0992C14.0341 10.3457 13.7785 10.5 13.5 10.5H5.25V16.5H3.75V1.5H5.25ZM5.25 9H12.0743L10.8196 7.17488C10.6436 6.91895 10.6436 6.58105 10.8196 6.32512L12.0743 4.5H5.25V9Z\" fill=\"#D92D20\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});