define("ember-svg-jar/inlined/anyone-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<rect width=\"24\" height=\"24\" rx=\"12\" fill=\"#EBEFF2\"/>\n<path d=\"M14.25 12C14.94 12 15.495 11.44 15.495 10.75C15.495 10.06 14.94 9.5 14.25 9.5C13.56 9.5 13 10.06 13 10.75C13 11.44 13.56 12 14.25 12ZM10.5 11.5C11.33 11.5 11.995 10.83 11.995 10C11.995 9.17 11.33 8.5 10.5 8.5C9.67 8.5 9 9.17 9 10C9 10.83 9.67 11.5 10.5 11.5ZM14.25 13C13.335 13 11.5 13.46 11.5 14.375V15.5H17V14.375C17 13.46 15.165 13 14.25 13ZM10.5 12.5C9.335 12.5 7 13.085 7 14.25V15.5H10.5V14.375C10.5 13.95 10.665 13.205 11.685 12.64C11.25 12.55 10.83 12.5 10.5 12.5Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});