define("ember-svg-jar/inlined/calendar-outline-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "\n<path d=\"M12.6667 2.66683H12V1.3335H10.6667V2.66683H5.33333V1.3335H4V2.66683H3.33333C2.59333 2.66683 2.00667 3.26683 2.00667 4.00016L2 13.3335C2 14.0668 2.59333 14.6668 3.33333 14.6668H12.6667C13.4 14.6668 14 14.0668 14 13.3335V4.00016C14 3.26683 13.4 2.66683 12.6667 2.66683ZM12.6667 13.3335H3.33333V6.66683H12.6667V13.3335ZM12.6667 5.3335H3.33333V4.00016H12.6667V5.3335ZM8 8.66683H11.3333V12.0002H8V8.66683Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});